export const priceDecimal = (string) => {
    if (string !== null && string !== undefined) {
        string = string.toString().replace(".00", "");

        string += "";
        let x = string.split(".");
        let x1 = x[0];
        let x2 = x.length > 1 ? "." + x[1] : "";
        let rgx = /(\d+)(\d{3})/;

        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, "$1" + "." + "$2");
        }

        return x1 + x2;
    } else {
        return "-";
    }
};

export const encodeQueryData = (data) => {
    let ret = [];
    
    for (let d in data) {
        let value = data[d];

        if (value !== '') {
            if (typeof value === "object") {
                value = data[d].join(",");
            }
    
            ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value));
        }
    }

    return ret.join("&");
};