import { combineReducers } from 'redux';

const jasaReducer = (state = {
	loadingCategory: true,
	dataCategory: [],
	dataList:[],
	dataKabupaten:[],
	loadingKabupaten: true,
	dataKecamatan:[],
	loadingKecamatan: true,
	dataDesa:[],
	loadingDesa: true,
	editData:[],
	loadingEditData: true,
	keyword: ''

}, action) => {
	switch (action.type) {
		case 'FETCH_CATEGORY_PENDING':
			return {
				...state,
				loadingCategory: true
			}
		case 'FETCH_CATEGORY_SUCCESS':
			return {
				...state,
				dataCategory: action.payload,
				loadingCategory: false
			}
		case 'FETCH_KABUPATEN_PENDING':
			return {
				...state,
				loadingKabupaten: true
			}
		case 'FETCH_KABUPATEN_SUCCESS':
			return {
				...state,
				dataKabupaten: action.payload,
				loadingKabupaten: false
			}
		case 'FETCH_KECAMATAN_PENDING':
			return {
				...state,
				loadingKecamatan: true
			}
		case 'FETCH_KECAMATAN_SUCCESS':
			return {
				...state,
				dataKecamatan: action.payload,
				loadingKecamatan: false
			}
		case 'FETCH_DESA_PENDING':
			return {
				...state,
				loadingDesa: true
			}
		case 'FETCH_DESA_SUCCESS':
			return {
				...state,
				dataDesa: action.payload,
				loadingDesa: false
			}
		case 'FETCH_LIST_PENDING':
			return {
				...state,
				loadingList: true
			}
		case 'FETCH_LIST_SUCCESS':
			return {
				...state,
				dataList: action.payload,
				loadingList: false
			}
		case 'FETCH_EDIT_PENDING':
			return {
				...state,
				loadingEditData: true
			}
		case 'FETCH_EDIT_SUCCESS':
			return {
				...state,
				editData: action.payload,
				loadingEditData: false
			}
		default:
			return state
	}
};

const rootReducer = combineReducers({
	jasa: jasaReducer
});

export default rootReducer;