import React, {useEffect, useState} from 'react';
import ReactGA from 'react-ga';
import './App.scss';
import { fetchCategory, fetchList} from '../actions/index';
import { connect } from 'react-redux';
import Navbar from '../components/navbar';
import Routers from '../routes';
import loadingIcon from '../assets/loading.gif';
import HeadHelmet from './HelmetHead';

ReactGA.initialize('G-71KVRJDEQV');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = p => {

  const [loading, setLoading] = useState(false)
  const waNumber = 81377284019
  const whatsapp = `https://api.whatsapp.com/send?phone=+62${waNumber}&text=Halo%20Awak%20Kerja,%20Saya%20mau%20nanya`

  let slug = window.location.pathname.replace('/', '')
  useEffect(() => {
    if(data.keyword !== ''){
		  p.fetchList(slug)
    }
  }, []);
  

  const [data, setData] = useState({
    keyword: '',
    slug : ''
	})

    const cariJasa = e => {
      if (e) e.preventDefault();
        let redirect = '/' + data.keyword
        window.location.href = redirect;
    }

    p = {
      ...p,
      loading: loading,
      setLoading: setLoading
    }


    if(loading){
      return (
        <div className="loading-background">
            <img title='loading-awak-kerja' alt='loading'  className="loading" src={loadingIcon}/>
        </div>
      )
    }
  
    let meta = {
      title: 'Layanan Peyedia Jasa Terlengkap dan Termurah - awakkerja.com',
      keywords: 'cari layanan peyedia jasa terlengkap dan termurah di awakkerja.com',
      desc: 'Temukan berbagai macam layanan peyedia jasa terlengkap dan termurah di awakkerja.com',
      image: 'https://api.awakkerja.com/public/public/assets/logo1.png',
      url: window.location.origin,
      page: window.location.origin,
      user: 'Awak Kerja'
  };

    return (
      <div>
        <HeadHelmet {...meta}/>
        <Navbar {...p} data={data} cariJasa={cariJasa} setData={setData}/>
        <Routers {...p} slug={slug}/>
        <div className="col-12 text-align-center footer row margin-0">
            <span className="">© {new Date().getFullYear()} Awak Kerja</span>
        </div>
        <div>
          {window.location.pathname === '/' ?
            <a href={whatsapp}>
              <img title='whatsapp-awak-kerja' alt='whatsapp'  className='floating-icon' src='https://api.awakkerja.com/public/public/assets/hubungikamiwa.png'/>
            </a>
            :
            ''
          }
        </div>
      </div>
    );
    }



const mapStateToProps = state => {
	return {
    dataList: state.jasa.dataList,
	}
}

const mapDispatchToProps = dispatch => {
	return {
    fetchList: (keyword) => {
			dispatch(fetchList(keyword))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

