import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios'
import localStorage from 'localStorage';
import './style.scss'

const ModalLogin = (props) => {
    
    const [authVerification, setAuthVerification] = useState('')
    const [data, setData] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const togglePassword = () => {
        setPasswordShow(!passwordShow);
    };

    let url = 'https://api.awakkerja.com/public/Api';

    const successLogin = (response) => {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('id', response.data.identity)
        localStorage.setItem('token_date', new Date());
        localStorage.setItem('whatsapp', response.data.whatsapp);
        localStorage.setItem('phone_number', response.data.phone_number)

        let prevUrl = localStorage.getItem('prevUrl');

        if (prevUrl ===  null) {
            prevUrl = '';
        } else {
            localStorage.removeItem('prevUrl');
        }

        window.location.href = prevUrl;
   }

    const login = (e) => {
        if (e) e.preventDefault();
        // props.setLoading(true)
        let formData = new FormData();
        formData.append('username',data.username)
        formData.append('password',data.password)
		axios.post(`${url}/auth`, formData, { headers: { 'Content-Type' : 'multipart/form-data' }})
			.then(response => {
                    // props.setLoading(false)
                    successLogin(response)
                    window.location.href = '/'
			})
			.catch(error => {
                // props.setLoading(false)
				;
                (authVerification === 'Email atau Password salah!') ? setAuthVerification('Email atau Password Masih salah!') : setAuthVerification('Email atau Password salah!')     
				throw (error)
			})
    }
    
    return (
        <div className="container">
        <Modal.Header closeButton>

          <Modal.Title>Sign In</Modal.Title>

        </Modal.Header>

        <Modal.Body>
            <div className="auth hide fade-in-right">
                {authVerification}
            </div>
            <form>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" name="email" aria-describedby="emailHelp" onChange={e => setData({ ...data, username: e.target.value })} />
                </div>
                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <input type={passwordShow ? "text" : "password"} className="form-control" name="password" onChange={e => setData({ ...data, password: e.target.value })} />
                    <div className='form-check show-password'>
                    <input className='form-check-input' type="checkbox" value="" onClick={togglePassword}/>
                    <label className='form-check-label' >
                        Tampilkan Password
                    </label>
                    </div>
                </div>
                <div className="mt-65">
                    <button type="submit" className="btn btn-primary col-12" onClick={login}>Login</button>
                </div>
                <div className="forgot-password">Belum punya akun? <a href="/register">Daftar Disini</a></div>
            </form>
        </Modal.Body>
    </div>
      );
}

export default ModalLogin;