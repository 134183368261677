import React from 'react'
import { Helmet } from "react-helmet";

const HelmetHead = p => {

  return (
      <Helmet>
          <meta charSet="utf-8" />
          <title>{p.title}</title>
          <meta name="description" content={p.desc}/>
          <meta name="keywords" content={p.keywords} />
          <meta name="image" content={p.image}/>
          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="Awak Kerja"/>
          <meta name="twitter:title" content={p.title}/>
          <meta name="twitter:description" content={p.desc}/>
          <meta name="twitter:creator" content={p.user}/>
          <meta name="twitter:image:src" content={p.image}/>
          <meta name="twitter:image" content={p.image}/>
          <meta name="twitter:domain" content="awakkerja.com"/>
          <meta property="og:title" content={p.title}/>
          <meta property="og:description" content={p.desc}/>
          <meta property="og:type" content="website"/> 
          <meta property="og:url" content={p.page}/>
          <meta property="og:image" content={p.image}/>
          <meta name="robots" content="index,follow"/>
          {/* <meta property="fb:app_id" content="894583347330814"/> */}
          <link rel="canonical" href={p.page}/>
      </Helmet>
  );
};

export default HelmetHead;

