import React, {useState} from 'react';
import ModalLogin from '../components/modal/login'
import { Modal } from 'react-bootstrap';
import { BsPersonCircle, BsDoorOpenFill } from "react-icons/bs";
const token = localStorage.getItem('token')


const Navbar = p => {

    let data = p.data;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const logo = 'https://api.awakkerja.com/public/public/assets/logo1.png'

    const handleShow = (page) => {
        if(token){
          window.location.href = page
        }else{
        setShow(true);
        }
    }
    const logout = (e) => {
      localStorage.clear()
      window.location.href = '/';
  }  

    return (
        <div className="navbar-light">
        <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered justify-content="center">
          <ModalLogin {...p}/>
        </Modal>
        <div className="row justify-content-center margin-0">
          <nav className="navbar navbar-expand-lg col-md-8">
            <a className="navbar-brand" href="/"><img title='logo-awak-kerja' alt='logo-awak-kerja' className="navbar-logo" src={logo}/></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                <li className="nav-item active">
                  <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={e => handleShow('/iklan-saya')}>Iklanku</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={e => handleShow('/pasang-iklan')}>Pasang Iklan</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href='https://bit.ly/LaporMasalahAwaKerja'>Kirim Saran</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={(token)? logout : e => handleShow()}>{token ? <BsDoorOpenFill className='icon'/> : <BsPersonCircle className='icon'/> } {(token)? ' Keluar' : ' Daftar/Masuk'}</a>
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0 d-md-block d-none" onSubmit={p.cariJasa}>
                <input className="form-control mr-sm-2 d-md-block d-none" type="input" value={data.keyword} id="search" name='keyword' placeholder="Cari penyedia jasa" onChange={e => p.setData({ ...data, keyword: e.target.value })}/>
              </form>
              <button className="btn btn-outline-success my-2 my-sm-0 d-md-block d-none" onClick={p.cariJasa}>Cari</button>
            </div>
          </nav>
        </div>
        <div className='d-block d-md-none'>
          <div>
            <div className='col-12'>
              <form className="form-inline my-2 my-lg-0" onSubmit={p.cariJasa}>
                  <input className="form-control mr-sm-2 search" type="input" value={data.keyword} id="search" name='keyword' placeholder="Cari penyedia jasa" onChange={e => p.setData({ ...data, keyword: e.target.value })}/>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Navbar;