import React, { useEffect } from 'react';
import { fetchCategory } from '../../../actions';
import { connect } from 'react-redux';
import {Skeleton} from 'react-loading-skeleton/dist/skeleton.css'
import './style.scss'
import bannerRental from '../../../assets/awak-kerja-rental-mobil.svg'
import BookingForm from '../bookingForm';
import { Card } from "react-bootstrap";

const Home = p => {

	useEffect(() => {
		p.fetchCategory('')
	}, []);

	const url = 'https://api.awakkerja.com/public'

	const featured_services = [
		{
			title: 'Jasa reparasi Laptop dan Komputer',
			slug: '/1/jasa-reparasi-laptop-dan-komputer',
			image: 'https://api.awakkerja.com/public/public/uploads/1642552433_cd27b23c40e880de008e.jpg',
			price: 'Rp 50.000',
			location: 'Banda Aceh'
		},
		{
			title: 'Guide Diving Wisata Sabang',
			slug: '/30/guide-diving-wisata-sabang',
			image: 'https://api.awakkerja.com/public/public/uploads/1665240397_ff0f9ec5628aa5d4494b.jpeg',
			price: 'Rp 200,000',
			location: 'Banda Aceh'
		},
		{
			title: 'Bongkar Pasang Gorden',
			slug: '/4/bongkar-pasang-gorden',
			image: 'https://api.awakkerja.com/public/public/uploads/1644422023_62d40566c93649d53ef8.jpeg',
			price: 'Rp 35.000',
			location: 'Kota Sabang'
		},
		{
			title: 'Terima jasa angkutan barang Banda Aceh - Aceh Besar',
			slug: '/13/terima-jasa-angkutan-barang-banda-aceh---aceh-besar',
			image: 'https://api.awakkerja.com/public/public/uploads/1645063255_a614865d03fd15f4044f.jpg',
			price: 'Rp 100.000',
			location: ' '
		}
	]

	const props = {
		...p,
		featured_services,
		url
	}

	return (
		<div>
			<div className="row justify-content-center margin-0">
				<div className="home shadow" >
					<img src={bannerRental || <Skeleton />} loading="lazy" className="width-100"/>
				</div>
				<div className="col-md-6 form mb-4">
					<BookingForm/>
				</div>
				<div className='col-md-8 car-list'>
					<div className='row text-align-center'>
						<div className='col-md-3 mb-2'>
							<Card className='price-list'>
								<img src='https://www.toyota.astra.co.id/sites/default/files/2021-11/4-avanza-silver-mica-metallic.png'className="car-image"/>
								<span className='title'>AVANZA</span>
								<span className='price'>Rp 300.000</span>
							</Card>
						</div>
						<div className='col-md-3 mb-2'>
							<Card className='price-list'>
								<img src='https://www.toyota.astra.co.id/sites/default/files/2020-10/4_innova-attitude-black_0.png'className="car-image"/>
								<span className='title'>INNOVA REBORN</span>
								<span className='price'>Rp500.000</span>
							</Card>
						</div>
						<div className='col-md-3'>
							<Card className='price-list'>
								<img src='https://www.toyota.astra.co.id/sites/default/files/2022-04/2%20hiace%20premio%20white.png'className="car-image"/>
								<span className='title'>HIACE-G</span>
								<span className='price'>Rp 1.200.000</span>
							</Card>
						</div>
						<div className='col-md-3 mb-2'>
							<Card className='price-list'>
								<img src='https://img.cintamobil.com/2021/11/06/E7uYC7dI/brio-teffate-white-138c.png'className="car-image"/>
								<span className='title'>BRIO-G</span>
								<span className='price'>Rp 250.000</span>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateTop = state => {
	return {
		dataCategory: state.jasa.dataCategory
	}
}

const mapDispatchTop = dispatch => {
	return {
		fetchCategory: (params) => {
			dispatch(fetchCategory(params))
		}
	}
}

export default connect(mapStateTop, mapDispatchTop)(Home);