import axios from 'axios'
import { encodeQueryData } from '../components/helper';
const token = localStorage.getItem('token')

export const fetchCategory = (params) => {
	return (dispatch) => {
		dispatch({
			type: 'FETCH_CATEGORY_PENDING'
		});

		return axios.get('https://api.awakkerja.com/public/Api/categories' + params)
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_CATEGORY_SUCCESS',
					payload
                })
			})
			.catch(error => {
				throw (error)
			})
	}
}

export const fetchList = (keyword) => {
	return (dispatch) => {
		dispatch({
			type: 'FETCH_LIST_PENDING'
		});
		
		return axios.get(`https://api.awakkerja.com/public/Api/search/${encodeQueryData(keyword)}/page-1`)
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_LIST_SUCCESS',
					payload
				})
			})
			.catch(error => {
				throw (error)
			})	
	}
}

export const fetchKabupaten = (keyword) => {
	return (dispatch) => {
		dispatch({
			type: 'KABUPATEN'
		});
		
		return axios.get(`https://api.awakkerja.com/public/api/pasangiklan`, { headers: {
            Authorization: `Bearer ${token}`
                }
            })
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_KABUPATEN_SUCCESS',
					payload
				})
			})
			.catch(error => {
				throw (error)
			})
	}
}

export const fetchKecamatan = (id_kabupaten) => {
	return (dispatch) => {
		dispatch({
			type: 'KECAMATAN'
		});
		
		return axios.get(`https://api.awakkerja.com/public/api/pasangiklan/getState?getData=kecamatan&data=${id_kabupaten}`, { headers: {
            Authorization: `Bearer ${token}`
                }
            })
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_KECAMATAN_SUCCESS',
					payload
				})
			})
			.catch(error => {
				throw (error)
			})
	}
}

export const fetchDesa = (id_kecamatan) => {
	return (dispatch) => {
		dispatch({
			type: 'DESA'
		});
		
		return axios.get(`https://api.awakkerja.com/public/api/pasangiklan/getState?getData=desa&data=${id_kecamatan}`, { headers: {
            Authorization: `Bearer ${token}`
                }
            })
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_DESA_SUCCESS',
					payload
				})
			})
			.catch(error => {
				throw (error)
			})
	}
}

export const fetchEditData = (id) => {
	return (dispatch) => {
		dispatch({
			type: 'FETCH_EDIT_PENDING'
		});
		
		return axios.get(`https://api.awakkerja.com/public/api/pasangiklan/edit/${id}`, { headers: {
            Authorization: `Bearer ${token}`
                }
            })
			.then(response => {
				let payload = response.data;

				dispatch({
					type: 'FETCH_EDIT_SUCCESS',
					payload
				})
			})
			.catch(error => {
				throw (error)
			})	
	}
}

