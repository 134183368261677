import React, {useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import axios from 'axios'
import HelmetHead from '../../HelmetHead';
import './style.scss'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Details = (props) => {

    const [data, setData] = useState({
        "description": "",
        "title": "",
        "location": "",
        "price": "",
        "featured_image": "",
        "date_created": "",
        "phone_number": "",
        "whatsapp": "",
        "service_provider": "",
        "images": [
            "cdn.jasakota.com/sedotwc2.jpg"
        ]
    });

    const [modal, setModal] = useState(false)
    const [link, setLink] = useState('')
    const toggle = (data) => {
        setModal(!modal)
        setLink(data)
    }

    const whatsapp = `https://api.whatsapp.com/send?phone=+62${data.whatsapp}&text=Halo,%20Saya%20melihat%20iklan%20anda%20di%20awakkerja.com`
    const telp = `tel:${data.phone_number}`

    const callProvider = (link) => {
    const notif1 = `https://api.callmebot.com/whatsapp.php?phone=6281534609419&text=1%20transaksi%20telah%20terjadi%20untuk%20layanan%20${data.title}%20yang%20disediakan%20oleh%20${data.service_provider}%0A&apikey=3191435`
    const notif2 = `https://api.callmebot.com/whatsapp.php?phone=6281377284019&text=1%20transaksi%20telah%20terjadi%20untuk%20layanan%20${data.title}%20yang%20disediakan%20oleh%20${data.service_provider}%0A&apikey=3634409`
    
        axios.get(notif1)
        .then(response => {
            console.log('success')
        })

        axios.get(notif2)
        .then(response => {
            console.log('success')
        })

        setModal(!modal)
        window.open(link)

    }

    useEffect(() => {
		axios.get(`https://api.awakkerja.com/public/Api/detailjasa/show/${props.match.params.id}/${props.match.params.slug}`)
        .then(response => {
            const payload = response.data;
            setData(payload)
        })
    }, []);

    const url = 'https://api.awakkerja.com/public'

    const imageUrl = (image) => {
        const img = image !== null ? url + image?.replace('.','') : 'https://api.awakkerja.com/public/public/assets/no_image.png'
        return (
            img
        )
    }

    const dataImage = data.featured_image !== undefined ? imageUrl(data.featured_image) : ''

    let meta = {
        title: data.title + ' termurah di ' + data.location + ' | awakkerja.com',
        keywords: data.title + ' termurah dan terbaik di ' + data.location + ' di tahun ' + new Date().getFullYear() ,
        desc: 'Layanan ' + data.title + ' termurah dan terbaik di ' + data.location + ' di tahun ' + new Date().getFullYear() + ' dari penyedia jasa ' + data.service_provider + ' hanya di awakkerja.com',
        image: dataImage,
        url: window.location.origin + props.location.pathname,
        page: window.location.href,
        user: data.service_provider
    };

    return (
        <div>
            <div>
                <Modal isOpen={modal} toggle={()=> toggle} centered>
                    <ModalHeader><span className='color-red'>Peringatan !!!</span></ModalHeader>
                    <ModalBody>
                        <span>Waspadalah terhadap segala bentuk penipuan!!!</span><br/>
                        <span>Hindari melakukan pembayaran/transfer dana sebelum bertemu penyedia jasa</span>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={()=> callProvider(link)}>
                        Saya Paham, Lanjut Hubungi
                    </Button>{' '}
                    <Button color="secondary" onClick={()=> setModal(!modal)}>
                        Batal
                    </Button>
                    </ModalFooter>
                </Modal>
            </div>
            <HelmetHead {...meta}/>
            <div className="row margin-0 justify-content-center">
                <div className="col-md-8" style={{ width:'100%', padding:30}}>
                    <div style={{marginBottom:50}} className='image-center'>
                        <img alt={data.title} title={data.title} className="card detail-image" src={imageUrl(data?.featured_image) || <Skeleton />}></img>
                    </div>
                    <div>
                        <hr style={{marginBottom:50}}/>
                    </div>
                    <div>
                        <h1>{data?.title || <Skeleton />}</h1>     
                    </div>
                    <div style={{marginBottom:30}}>
                        <a className='white-space-pre'>{data.description || <Skeleton />}</a>
                    </div>
                    <div>
                        <a>{`Penyedia jasa :  ${data.service_provider}` || <Skeleton />}</a>
                    </div>
                    <div>
                        <a>{`Lokasi :  ${data.location !== undefined ? data.location : '-'}` || <Skeleton />}</a>
                    </div>
                    <div style={{marginBottom:30}}>
                        <a>{`Harga :  ${data.price}`}</a>
                    </div>
                    <div className="row justify-content-center">
                        <div>
                            <center className='d-md-block d-none'>
                                <a onClick={()=> toggle(telp)} style={{margin:10}}>
                                    <button className="btn btn-primary"><img alt={data.phone_number} title={data.title} src='https://cdn-icons-png.flaticon.com/128/901/901141.png' className='whatsapp-icon'/>{data.phone_number}</button>
                                </a>
                                <a onClick={()=> toggle(whatsapp)} style={{margin:10}}>
                                    <div className="btn btn-primary"><img alt={data.whatsapp} title={data.title} src='https://seeklogo.com/images/W/whatsapp-icon-logo-6E793ACECD-seeklogo.com.png' className='whatsapp-icon'/>{data.whatsapp}</div>
                                </a>
                            </center>
                        </div>
                        <div className='d-md-none d-block'>
                            <center>
                                <a onClick={()=> toggle(telp)} style={{margin:10}}>
                                    <button className="btn btn-primary">Hubungi Penyedia Jasa</button>
                                </a>
                                <br/><br/>
                                <a onClick={()=> toggle(whatsapp)} style={{margin:10}}>
                                    <div className="btn btn-primary">Whatsapp Penyedia Jasa</div>
                                </a>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details