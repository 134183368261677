import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'
import './index.scss'
import { Eye, EyeOff } from 'react-feather';



const Register = (props) => {

    const [data, setData] = useState({
        username: '',
        first_name: '',
        last_name: '',
        title: '',
        phone_number: '',
        whatsapp: '',
        password: '',
        repeat_password: ''
    });

    let url = 'https://api.awakkerja.com/public/Api';

    const [ hidePassword, setHidePassword ] = useState(true)
    const [ hideRepeatPassword, setHideRepeatPassword ] = useState(true)


    const showToastMessage = (status, message) => {
        if(status === 'sukses'){
            toast.success('Pendaftaran Berhasil !', {
                position: "bottom-right",
                autoClose: 2000,
            })
        }else{
            toast.error(message, {
                position: "bottom-right",
                autoClose: 2000,
            })
        }
    };

    const submit = e => {
        if (e) e.preventDefault();
        let formData = new FormData();
        formData.append('username', data.username)
        formData.append('first_name', data.first_name)
        formData.append('last_name', data.last_name)
        formData.append('title', data.title)
        formData.append('phone_number', data.phone_number)
        formData.append('whatsapp', data.whatsapp)
        formData.append('password', data.password)
        if(data.password === data.repeat_password){
            axios.post(`${url}/auth/create`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    showToastMessage('sukses')

                    const notif1 = `https://api.callmebot.com/whatsapp.php?phone=6281534609419&text=Selamat%20!!!%20,%20Kita%20baru%20saja%20mendapatkan%201%20user%20baru,%20dengan%20username%20${data.username}%0A&apikey=3191435`
                    const notif2 = `https://api.callmebot.com/whatsapp.php?phone=6281377284019&text=Selamat%20!!!%20,%20Kita%20baru%20saja%20mendapatkan%201%20user%20baru,%20dengan%20username%20${data.username}%0A&apikey=3634409`
                  
                    axios.get(notif1)
                    .then(response => {
                        console.log('success')
                    })

                    axios.get(notif2)
                    .then(response => {
                        console.log('success')
                    })

                    window.location.href = "/iklan-saya";
                })
                .catch(error => {
                    showToastMessage('gagal', error.response.data.message)
                    let response = error.response.data.formErrors
                    for (let key in data) {
                        document.getElementById('user_' + key).classList.replace('d-block', 'd-none')
                    }
                    for (let key in response) {
                        const elForm = document.getElementById(key);
                        if (elForm) {
                            document.getElementById('user_' + key).classList.add('d-block')
                            document.getElementById('user_' + key).innerHTML = response[key]
                        }
                    }
                    throw (error)
                })
            }else{
                showToastMessage('gagal', 'Password yang kamu masukkan tidak sama')
            }
    } 
    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-md-6 card margin-top-60 box-shadow">
                    <div className="col-md-12">
                        <form className="form-padding">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" id="username" className="form-control" name="username" placeholder="Masukkan Alamat Email" onBlur={e => setData({ ...data, username: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_username" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Nama Depan</label>
                                <input type="text" id="first_name" className="form-control" name="first_name" placeholder="Masukkan Nama Depan" onBlur={e => setData({ ...data, first_name: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_first_name" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Nama Belakang</label>
                                <input type="text" id="last_name" className="form-control" name="last_name" placeholder="Masukkan Nama Belakang" onBlur={e => setData({ ...data, last_name: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_last_name" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Nama Perusahaan</label>
                                <input type="text" id="title" className="form-control" name="last_name" placeholder="*Kosongkan bila tidak ada" onBlur={e => setData({ ...data, title: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_title" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Nomor Hp</label>
                                <input type="text" id="phone_number" className="form-control" name="last_name" placeholder="Masukkan Nomor Hp" onBlur={e => setData({ ...data, phone_number: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_phone_number" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Whatsapp</label>
                                <input type="text" id="whatsapp" className="form-control" name="last_name" placeholder="Masukkan Nomor Whatsapp" onBlur={e => setData({ ...data, whatsapp: e.target.value })} />
                                <div className="invalid">
                                    <p id="user_whatsapp" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Kata Sandi</label>
                                <input type={hidePassword ? 'password' : 'text'} id="password" className="form-control" name="password" placeholder="Masukkan Kata Sandi" onBlur={e => setData({ ...data, password: e.target.value })} />
                                {hidePassword ? <EyeOff className='password' onClick={e=>setHidePassword(false)}/> : <Eye className='password' onClick={e=>setHidePassword(true)}/>}
                                <div className="invalid">
                                    <p id="user_password" className=''></p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Verifikasi Kata Sandi</label>
                                <input type={hideRepeatPassword ? 'password' : 'text'} id="password" className="form-control" name="passwordRepeat" placeholder="Masukkan Ulang Kata Sandi" onBlur={e => setData({ ...data, repeat_password: e.target.value })}/>
                                {hideRepeatPassword ? <EyeOff className='password' onClick={e=>setHideRepeatPassword(false)}/> : <Eye className='password' onClick={e=>setHideRepeatPassword(true)}/>}
                                <div className="invalid">
                                    <p id="user_repeat_password" className=''></p>
                                </div>
                            </div>
                            <button type="submit" onClick={submit} className="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <ToastContainer 
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
        </div>
    )
}

export default Register