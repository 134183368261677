import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import HelmetHead from '../../HelmetHead';
import 'react-loading-skeleton/dist/skeleton.css'
import axios from 'axios'
import './style.scss'


const Listjasa = (p, {match}) => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      }

    let query = useQuery();
    let page = (query.get("page") === null) ? '1' : query.get("page")
    let id_category = query.get("category")
    let category = (id_category === null) ? '' : '?category=' + id_category

    const api = (p.match.path === '/kategori/:id') ? `https://api.awakkerja.com/public/Api/categories/${p.match.params.id}/page-${page}` : `https://api.awakkerja.com/public/Api/search/${p.match.params.slug}/page-${page}` + category
    const [data, setData] = useState([]);
    

    useEffect(() => {
		axios.get(api)
        .then(response => {
            const payload = response.data;
            setData(payload)
        })
    }, []);

    let pageNumber = [];
    let i = 1;
    let x;

    let total_page =  data.total_page + 1
    
    while (i < total_page) {
        
        x = <li className={(i === page) ? 'page-item active font-white' : 'page-item'}><a className="page-link" href={"?page=" + i}>{i}</a></li>;
        pageNumber.push(x)
        i++;
      }

    let Jasa = (p.match.path === '/kategori/:id') ? data.data : data

    const url = 'https://api.awakkerja.com/public'

    let filterCategory = Jasa?.categories?.find(x => x.id_master_categories === id_category)

    const imageUrl = (image) => {
        const img = image !== null ? url + image?.replace('.','') : 'https://api.awakkerja.com/public/public/assets/no_image.png'
        return (
            img
        )
    }

    const keyword = data.category_title ?  data.category_title :  p.match.params.slug

    let meta = {
        title: 'Jasa ' + keyword + ' termurah di tahun ' + new Date().getFullYear() + ' | awakkerja.com',
        desc: 'Jasa ' + keyword + ' termurah dan terbaik di tahun ' + new Date().getFullYear(),
        image: 'https://api.awakkerja.com/public/public/assets/no_image.png',
        keyword: 'Jasa ' + keyword + ' termurah dan terbaik di tahun ' + new Date().getFullYear(),
        url: window.location.origin + p.location.pathname,
        page: window.location.href,
        user: 'awak kerja'
    };

    return (
        <div>
            <HelmetHead {...meta}/>
            <div className="row margin-0 justify-content-center">
                <div className="col-md-8" style={{width:'100%', padding:30}}>
                    <div className='row head-section'>
                        <div>
                            <h1 className='keyword'>{(data.category_title)? 'Menampilkan kategori ' + data.category_title : (p.match.params.slug === undefined)? 'Belum ada jasa untuk kategori ini' : (data.data && data.data.length < 1)? 'Hasil pencarian untuk '  + p.match.params.slug + ' tidak ditemukan' : 'Menampilan hasil pencarian ' + p.match.params.slug}</h1>
                        </div>
                        <div className='row m-0'>
                            Pilih Kategori :
                            <div className="dropdown ml-2">
                                <a className=" dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {id_category ? filterCategory?.title : 'Semua Kategori'}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item" href='jasa'>Semua Kategori</a>
                                    {Jasa?.categories?.map((item, i) => {
                                        return(
                                            <a className="dropdown-item" href={`jasa?category=${item.id_master_categories}`}>{item.title}</a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    {(p.match.path === '/kategori/:id') ?
                         Jasa && Jasa.map((item, key) =>{
                            return (
                            <div key={key}>
                                <div className="row justify-content-center">
                                    <a href={`/${item.id_various_services}/${item.slug}`} className="col-md-3 col-4">
                                        <img loading={key > 0 ? "lazy" : ''} title={item.title} alt={item.title} className='image-list w-100' src={imageUrl(item.featured_image) || <Skeleton />}/>
                                    </a>
                                    <div className="col-md-9 col-8">
                                        <a href={`/${item.id_various_services}/${item.slug}`}>
                                            <h2 className='title'>
                                                <b>{item.title || <Skeleton />}</b>
                                            </h2>
                                        </a>
                                        <div>
                                            <a href={`/${item.id_various_services}/${item.slug}`}>Lokasi : {item.location || <Skeleton />}</a>
                                        </div>
                                        <div>
                                            <a href={`/${item.id_various_services}/${item.slug}`}>Harga : Rp {item.price || <Skeleton />}</a>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginBottom:10}}/>
                            </div>
                        )
                        } )
                        :
                        data.data && data.data.map((item, key) =>{
                            return (
                            <div>
                                <div className="row justify-content-center">
                                    <a href={`/${item.id_various_services}/${item.slug}`} className="col-md-3 col-4">
                                        <img loading={key > 0 ? "lazy" : ''}  title={item.title} alt={item.title} className='image-list w-100' src={imageUrl(item.featured_image)}/>
                                    </a>
                                    <div className="col-md-9 col-8">
                                        <a href={`/${item.id_various_services}/${item.slug}`}>
                                            <h2 className='title'>
                                                <b>{item.title || <Skeleton />}</b>
                                            </h2>
                                        </a>
                                        <div>
                                            <a href={`/${item.id_various_services}/${item.slug}`}>Lokasi : {item.location || <Skeleton />}</a>
                                        </div>
                                        <div>
                                            <a href={`/${item.id_various_services}/${item.slug}`}>Harga : Rp {item.price || <Skeleton />}</a>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{marginBottom:10}}/>
                            </div>
                        )
                        } )
                        }

                    <div className="row justify-content-center">
                        <nav aria-label="...">
                            <ul className="pagination">
                                <li className={ (page === 1) ? "page-item disabled" : "page-item"}>
                                <a className="page-link" href={'?page='+ (parseInt(page)-1)}>Previous</a>
                                </li>
                                {pageNumber}
                                <li className={ (page === data.total_page) ? "page-item disabled" : "page-item"}><a className="page-link" href={'?page='+ (parseInt(page)+1)}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Listjasa;