import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import { priceDecimal } from '../../helper';
import './style.scss';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import ModalLogin from '../../modal/login';
const token = localStorage.getItem('token')
const userId = localStorage.getItem('id')

const IklanSaya = (p) => {


    const [show, setShow] = useState(
        (token === null)? true : false
    )
    const handleClose = () => window.location.href = "/";
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      }

    let query = useQuery();
    let page = (query.get("page") === null) ? '1' : query.get("page")

    const api = `https://api.awakkerja.com/public/Api/pasangiklan/show`
    const [data, setData] = useState([]);
    
    const hapusIklan = data => e => {
        p.setLoading(true)
        if (e) e.preventDefault();
        axios.post(`https://api.awakkerja.com/public/Api/pasangiklan/delete/${data.enc}`, {data : {userId : data.id_service_provider} } , { headers: { Authorization: `Bearer ${token}` }})
          .then(response => {
              p.setLoading(false)
          })  
    }

    const url = 'https://api.awakkerja.com/public'

    useEffect(() => {
        p.setLoading(true)
		axios.get(api,{ headers: {
            Authorization: `Bearer ${token}`
                }
            })
        .then(response => {
            const payload = response.data;
            setData(payload)
        })
        .catch(error => {
            throw (error),
            setShow(true)
        })	
        p.setLoading(false)
    }, []);

    let pageNumber = [];
    let i = 1;
    let x;
    
    let total_page =  data.total_page + 1
    
    while (i < total_page) {
        
        x = <li className={(i ===  page) ? 'page-item active font-white' : 'page-item'}><a className="page-link" href={"?page=" + i}>{i}</a></li>;
        pageNumber.push(x)
        i++;
      }

    let Jasa = (p.match.path ===  '/kategori/:id') ? data.data : data

    const verificationBadge = (verification) => {
        switch (verification) {
            case '0':
                return 'badge badge-warning'
                break;
            case '1':
                return 'badge badge-success'
                break;
        
            case '2':
                return 'badge badge-danger'
                break;
            
            default:
                break;
        }
    }

    const badgeLabel = (label) => {
        switch (label) {
            case '0':
                return 'Menunggu verifikasi'
                break;
            case '1':
                return 'Iklan sudah tayang'
                break;
        
            case '2':
                return 'Iklan anda ditolak'
                break;
            
            default:
                break;
        }
    }

    const editIklan = (data) => {
        window.location.href = '/edit-iklan/' + data
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered justify-content="center">
                <ModalLogin/>
            </Modal>
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8" style={{width:'100%', margin:30}}>
                        <div style={{marginBottom:50}}>
                            Daftar iklan kamu
                        </div>
                        {(p.match.path ===  '/kategori/:id') ?
                            Jasa && Jasa.map((item, key) =>{
                                return (
                                <div>
                                    <div className="row justify-content-center">
                                        <a href={`/${item.id_service_provider}/detail`} className="col-md-3 col-4">
                                            <img title={item.title} alt={item.title} className='image-list' src={item.featured_image ? url + item.featured_image.replace('.','') : 'https://api.awakkerja.com/public/public/assets/no_image.png'}/>
                                        </a>
                                        <div className="col-md-9 col-8">
                                            <a href={`/${item.id_service_provider}/detail`}>
                                                <h5>{item.title}</h5>
                                            </a>
                                            <div>
                                                <a href={`/${item.id_service_provider}/detail`}>Lokasi : {item.location}</a>
                                            </div>
                                            <div>
                                                <a href={`/${item.id_service_provider}/detail`}>Harga : Rp {priceDecimal(item.price)}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style={{marginBottom:10}}/>
                                </div>
                            )
                            })
                            :
                            data.data?.length > 0 ? 
                                data.data && data.data.map((item, key) =>{
                                    return (
                                    <div>
                                        <div className="row justify-content-center">
                                            <a href={`/${item.id_various_services}/${item.slug}`} className="col-md-3 col-4">
                                                <img title={item.title} alt={item.title} className='image-list' src={item.featured_image ? url + item.featured_image.replace('.','') : 'https://api.awakkerja.com/public/public/assets/no_image.png'}/>
                                            </a>
                                            <div className="col-md-9 col-8">
                                                
                                                    <div className="row col-12 space-between">
                                                        <div><h5>{item.title}</h5></div>
                                                        <div className="subtitle btn btn-primary d-none d-md-block">
                                                            <span onClick={e => editIklan(item.enc)}>Edit Iklan</span>
                                                        </div>
                                                    </div>
                                                
                                                <div className="row col-12 space-between mt-3">
                                                    <div>
                                                        <a className="subtitle" href={`/${item.id_various_services}/${item.slug}`}>Lokasi : {item.location}</a>
                                                    </div>
                                                    <div className="subtitle btn btn-danger d-none d-md-block">
                                                        <span href="#" onClick={hapusIklan(item)} >Hapus Iklan</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <a className="subtitle" href={`/${item.id_various_services}/${item.slug}`}>Harga : Rp {priceDecimal(item.price)}</a>
                                                </div>
                                                <div>
                                                    <a className="subtitle">Jumlah iklan ditayangkan : {item.show_count}x</a>
                                                </div>
                                                <div>
                                                    <a className="subtitle">Jumlah iklan dilihat : {item.view_count}x</a>
                                                </div>
                                                <div className='badges'>
                                                    <span className={verificationBadge(item.verification)}>{badgeLabel(item.verification)}</span>
                                                </div>
                                                {
                                                    item.verification ===  "2" ?
                                                    <div>
                                                        <div className='subtitle'>
                                                            <span className='badge badge-warning'>Alasan penolakan : {item.rejected_message}</span>
                                                        </div>
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <div className="subtitle btn btn-primary d-block d-md-none mt-15">
                                                    <span href="#" onClick={e => editIklan(item.enc)} >Edit Iklan</span>
                                                </div>

                                                <div className="subtitle btn btn-danger d-block d-md-none mt-15">
                                                    <span href="#" onClick={hapusIklan(item)} >Hapus Iklan</span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{marginBottom:10}}/>
                                    </div>
                                )
                                })
                                :
                                <div>
                                    <center>
                                        <h5>Kamu belum memasang iklan apapun</h5>
                                        <span className='btn btn-primary' onClick={() => window.location.href="/pasang-iklan"}>Mulai Pasang Iklan</span>
                                    </center>
                                </div>
                            }
                        {data.data?.length > 0 ?
                            <div className="row justify-content-center">
                            <nav aria-label="...">
                                <ul className="pagination">
                                    <li className={ (page ===  1) ? "page-item disabled" : "page-item"}>
                                    <a className="page-link" href={'?page='+ (parseInt(page)-1)}>Previous</a>
                                    </li>
                                    {pageNumber}
                                    <li className={ (page ===  data.total_page) ? "page-item disabled" : "page-item"}><a className="page-link" href={'?page='+ (parseInt(page)+1)}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        :
                        ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default IklanSaya;