import React, {useState, useEffect} from 'react'
import axios from 'axios'
import { Modal, Button, Spinner } from 'react-bootstrap'
import ModalLogin from '../../modal/login'
import { fetchKabupaten, fetchKecamatan, fetchDesa, fetchEditData, fetchCategory} from '../../../actions/index';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.scss'
import  { priceDecimal } from '../../helper';
const token = localStorage.getItem('token')
const whatsapp = localStorage.getItem('whatsapp')

const PasangIklan = (p) => {
    const otherUser = (whatsapp === '081262981645' ? false : true)
    const specialAcount = (whatsapp === '081262981645' ? true : false)
    const [data, setData] = useState({
        title : '',
        description: '',
        id_kabupaten : '',
        id_kecamatan : '',
        id_desa : '',
        id_master_categories: '',
        location : '',
        price: '',
        name_service_provider : '',
        whatsapp: '',
        phone_number : ''
    });
    const [show, setShow] = useState((token === null)? true : false);
    const [loadingButton, setLoadingButton] = useState(false)
    const handleClose = () => window.location.href = "/";
    const [price, setPrice] = useState('')
    let url = 'https://api.awakkerja.com/public/Api';

    useEffect(() => {
        if(p.match.path === '/edit-iklan/:id' ){
            p.fetchEditData(p.match.params.id)
        }
    }, []);

    useEffect(() => {
		p.fetchCategory('?create=true')
  }, []);

    useEffect(() => {
        if(p.slug !== 'pasang-iklan'){
            setData(p.editData)
        }
    }, [p.editData]);

    useEffect(() => {
            p.fetchKabupaten()
    }, []);

    useEffect(() => {
        if(data.id_kabupaten){
            p.fetchKecamatan(data.id_kabupaten)
        }
    }, [data.id_kabupaten]);

    useEffect(() => {
        if(data.id_kabupaten){
            p.fetchDesa(data.id_kecamatan)
        }
    }, [data.id_kecamatan]);  
    
    const showToastMessage = (status, message) => {
        if(status === 'sukses'){
            toast.success('Pendaftaran Berhasil !', {
                position: "bottom-right",
                autoClose: 2000,
            })
        }else{
            toast.error(message, {
                position: "bottom-right",
                autoClose: 2000,
            })
        }
    };

    const submit = e => {
        if (e) e.preventDefault();
        let formData = new FormData();
        formData.append('title',data.title)
        formData.append('description',data.description)
        formData.append('price',data.price)
        formData.append('location',data.location)
        formData.append('id_kabupaten',data.id_kabupaten)
        formData.append('id_desa',data.id_desa)
        formData.append('id_kecamatan',data.id_kecamatan)
        formData.append('phone_number',data.phone_number)
        formData.append('whatsapp',data.whatsapp)
        if(data.image){
            formData.append('featured_image',data.image)
        }
        if(specialAcount){
            formData.append('name_service_provider',data.name_service_provider)
        }
        formData.append('id_master_categories',data.id_master_categories)
        if(p.slug !== 'pasang-iklan'){
            formData.append('id_various_services', data.id_various_services)
        }
        setLoadingButton(true)
        let addEdit = p.match.path === '/pasang-iklan' ? 'create' : 'update/' + p.editData?.id_various_services
		axios.post(
            `${url}/pasangiklan/${addEdit}`, formData, { headers: { 'Content-Type' : 'multipart/form-data', Authorization: `Bearer ${token}` 
        }})
			.then(response => {
                    setLoadingButton(false)
                    showToastMessage('sukses')
                    const notif1 = `https://api.callmebot.com/whatsapp.php?phone=6281534609419&text=Selamat%20!!!%20,%20ada%201%20iklan%20baru%20telah%20dibuat%20dengan%20judul%20${data.title},%20segera%20cek%20halaman%20admin%20untuk%20memverifikasi%0A&apikey=3191435`
                    const notif2 = `https://api.callmebot.com/whatsapp.php?phone=6281377284019&text=Selamat%20!!!%20,%20ada%201%20iklan%20baru%20telah%20dibuat%20dengan%20judul%20${data.title},%20segera%20cek%20halaman%20admin%20untuk%20memverifikasi%0A&apikey=3634409`
                  
                    axios.get(notif1)
                    .then(response => {
                        window.location.href = "/iklan-saya";
                    })

                    axios.get(notif2)
                    .then(response => {
                        window.location.href = "/iklan-saya";
                    })
			})
			.catch(error => {
                setLoadingButton(false)
                showToastMessage('gagal', error.response.data.message)
                let response = error.response.data.formErrors
                for(let key in data){
                    document.getElementById('service_' + key).classList.replace('d-block', 'd-none')
                }
                for(let key in response){
                    const elForm = document.getElementById(key);
                    if(elForm){  
                        document.getElementById('service_' + key).classList.add('d-block')
                        document.getElementById('service_' + key).innerHTML = response[key]
                    }
                }
            ;
            throw (error)
        })
    }

    const onHandleChangePrice = ev => {
        const { value } = ev.target
        
        let rawPrice = value.split('.').join('')

        rawPrice = +rawPrice

        if (typeof rawPrice === 'number' && isFinite(rawPrice)) {
            setData({...data, price : rawPrice})
            const price = changeToCurrencyFormat(rawPrice)
            setPrice(price)
        }
    }

    const changeToCurrencyFormat = (price) => {
        return (price).toLocaleString('id-ID', {
            style: 'decimal',
            currency: 'IDR',
        })
    }

    const uploadFile = ((e) => {
        if(e.target.files[0] !== undefined){
            setData({ ...data, image: e.target.files[0]})
        }
    })

    const submitLoading = () => {
        if(loadingButton){
            return (
                <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                />
            )
        }
    }

    return (
        <div>
            <ToastContainer 
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Modal show={show} onHide={handleClose} size="md" aria-labelledby="contained-modal-title-vcenter" centered justify-content="center">
                <ModalLogin/>
            </Modal>
            <div className="row justify-content-center">
                <div className="col-md-6 card margin-top-60 box-shadow">
                    <div className="col-md-12">
                    <form className="form-padding">
                        <div className="form-group">
                            <label>Judul Iklan</label>
                            <input type="text" className="form-control" id='title' defaultValue={p.editData.title} name="title" placeholder="Masukkan Judul Iklan" required  onChange={e => setData({ ...data, title: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_title" className=''></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kategori</label>
                            <select className="form-control form-control" id='id_master_categories' name="id_master_categories" onChange={e => setData({ ...data, id_master_categories: e.target.value })}>
                                <option value="" selected disabled>--Pilih Kategori--</option>
                                {p.category.map((item, key) => {
                                    return (                
                                        <option key={key} selected={item.id_master_categories === data.id_master_categories ? true : false} value={item.id_master_categories}>{item.title}</option>
                                    )
                                })}
                            </select>
                            <div className="invalid">
                                <p id="service_id_master_categories"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Deskripsi</label>
                            <textarea type="text" className="form-control description" value={data.description} id='description' name="description" placeholder="Masukkan Deskripsi"  onChange={e => setData({ ...data, description: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_description"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Lokasi</label>
                            <input type="text" className="form-control" value={data.location} id='location' name="location" placeholder="Masukkan Lokasi Usaha/Rumah"  onChange={e => setData({ ...data, location: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_location"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kabupaten / Kota</label>
                            <select className="form-control form-control" id="id_kabupaten" name="id_kabupaten" onChange={e => setData({ ...data, id_kabupaten: e.target.value })}>
                                <option value='' selected disabled>Pilih Kabupaten / Kota</option>
                                {p.dataKabupaten && p.dataKabupaten.map((item, key) => {
                                    return (                
                                        <option key={key} selected={item.id_kabupaten === data.id_kabupaten ? true : false} value={item.id_kabupaten}>{item.kabupaten}</option>                                   
                                    )
                                })}
                            </select><div className="invalid">
                                <p id="service_id_kabupaten"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kecamatan</label>
                            <select className="form-control form-control" id="id_kecamatan" name="id_kecamatan" onChange={e => setData({ ...data, id_kecamatan: e.target.value })}>
                                <option value='' selected disabled>Pilih Kecamatan</option>
                                {p.dataKecamatan && p.dataKecamatan.map((item, key) => {
                                    return (                
                                        <option key={key} selected={item.id_kecamatan === data.id_kecamatan ? true : false} value={item.id_kecamatan}>{item.kecamatan}</option>
                                    )
                                })}
                            </select><div className="invalid">
                                <p id="service_id_kecamatan"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Kelurahan / Desa</label>
                            <select className="form-control form-control" id="id_desa" name="id_desa" onChange={e => setData({ ...data, id_desa: e.target.value })}>
                                <option value='' selected disabled>Pilih Keluaran / Desa</option>
                                {p.dataDesa && p.dataDesa.map((item, key) => {
                                    return (                
                                        <option key={key} selected={item.id_desa === data.id_desa ? true : false} value={item.id_desa}>{item.desa}</option>
                                    )
                                })}
                            </select>
                            <div className="invalid">
                                <p id="service_id_desa"></p>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Biaya Layanan</label>
                            <input type="text" className="form-control" name="price" id='price' value={data.price ? priceDecimal(data.price) : price} placeholder="Masukkan Biaya" onChange={onHandleChangePrice}/>
                            <div className="invalid">
                                <p id="service_price"></p>
                            </div>
                        </div>
                        <div className={otherUser ? "form-group d-none" : "form-group"}>
                            <label>Penyedia Jasa</label>
                            <input type="text" className="form-control" id="name_service_provider" name="name_service_provider" placeholder='Masukkan Nama Penyedia Jasa' defaultValue={p.editData.name_service_provider} onChange={e => setData({ ...data, name_service_provider: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_name_service_provider"></p>
                            </div>
                        </div>
                        <div className={otherUser ? "form-group d-none" : "form-group"}>
                            <label>Nomor HP</label>
                            <input type="tel" disabled={otherUser} placeholder="Masukkan Nomor HP Penyedia Jasa" className="form-control" id="phone_number" name="phone_number" defaultValue={p.editData.phone_number} onChange={e => setData({ ...data, phone_number: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_phone_number"></p>
                            </div>
                        </div>
                        <div className={otherUser ? "form-group d-none" : "form-group"}>
                            <label>Whatsapp</label>
                            <input type="tel" disabled={otherUser} placeholder="Masukkan Nomor Whatsapp Penyedia Jasa" className="form-control" id="whatsapp" name="whatsapp" defaultValue={p.editData.whatsapp} onChange={e => setData({ ...data, whatsapp: e.target.value })}/>
                            <div className="invalid">
                                <p id="service_whatsapp"></p>
                            </div>
                        </div>
                        <div className="form-group row upload">
                            <div>
                                <img className='image'src={data.image ? URL.createObjectURL(data.image) : 'https://api.awakkerja.com/public/public/assets/no_image.png'}/>
                            </div>
                            <div className='upload-button'>
                                <div>Pilih Gambar</div>
                                <input type="file" onChange={e => uploadFile(e)}/>
                                <div className='color-red'>*ukuran gambar maksimal 200kb</div>
                            </div>
                        </div>
                        <Button variant="primary" className="btn btn-primary" onClick={submit}>
                            {submitLoading()}
                            {loadingButton ? ' Sedang diproses...' : 'Submit'}
                        </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
	return {
    dataKabupaten : state.jasa.dataKabupaten,
    dataKecamatan : state.jasa.dataKecamatan,
    dataDesa : state.jasa.dataDesa,
    editData : state.jasa.editData,
    category : state.jasa.dataCategory
	}
}

const mapDispatchToProps = dispatch => {
	return {
    fetchKabupaten: () => {
			dispatch(fetchKabupaten())
        },
    fetchKecamatan: (id_kabupaten) => {
			dispatch(fetchKecamatan(id_kabupaten))
        },
    fetchDesa: (id_desa) => {
			dispatch(fetchDesa(id_desa))
        },
    fetchEditData: (id) => {
			dispatch(fetchEditData(id))
        },
    fetchCategory: (params) => {
			dispatch(fetchCategory(params))
        }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PasangIklan)