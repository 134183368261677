import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import banner2 from '../../assets/banner-2.webp'
import banner3 from '../../assets/banner-3.webp'
import banner4 from '../../assets/banner-4.webp'



const Header = () => {
    return (
        <Carousel>
            <Carousel.Item>
                <a href="/kategori/angkutan">
                    <img
                        className="d-block w-100"
                        src={banner2}
                        alt="jasa Rental Mobil"
                    />
                </a>
            </Carousel.Item>
            <Carousel.Item>
                <a href="/kategori/konstruksi">
                    <img
                        className="d-block w-100"
                        src={banner3}
                        alt="Jasa Tukang Bangunan"
                    />
                </a>
            </Carousel.Item>
            <Carousel.Item>
                <a href="/kategori/foto-video">
                    <img
                        className="d-block w-100"
                        src={banner4}
                        alt="Jasa Fotografi"
                    />
                </a>
            </Carousel.Item>
        </Carousel>
    )
}

export default Header