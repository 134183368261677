import React, { useState } from "react";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import './booking.scss'
import Success from '../../assets/badge-check.png'

import Axios from "axios";

const BookingForm = () => {
    const [inputs, setInputs] = useState({});
    const [selectedValue, setSelectedValue] = useState()

    const handleSubmit = e => {
        e.preventDefault();
    };

    const handleInputChange = e => {
        e.persist();
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    };

    const handleChange = e => {
        e.persist();
        setInputs(inputs => ({ ...inputs, ["car"]: e.target.value }))
    }

    const [showForm, setShowForm] = useState(false)

    const notif1 = `https://api.callmebot.com/whatsapp.php?phone=6281534609419&text=Halo%2C%20Pesanan%20Rental%20Mobil%20baru%20masuk%20dengan%20rincian%20sebagai%20berikut%0A%0AAtas%20Nama%20%3A%20${inputs.name || ''}%0ANomor%20Hp%20%3A%20${inputs.phone || ''}%0Atanggal%20mulai%20sewa%3A%20${inputs.start_date || ''}%0AJumlah%20hari%3A%20${inputs.total_days || ''}%20hari%0AUnit%20Pilihan%3A%20${inputs.car || ''}%0ALokasi%20Pengantaran%3A%20${inputs.location || 'Ambil di kantor'}%0A&apikey=3191435`
    const notif2 = `https://api.callmebot.com/whatsapp.php?phone=6281377284019&text=Halo%2C%20Pesanan%20Rental%20Mobil%20baru%20masuk%20dengan%20rincian%20sebagai%20berikut%0A%0AAtas%20Nama%20%3A%20${inputs.name || ''}%0ANomor%20Hp%20%3A%20${inputs.phone || ''}%0Atanggal%20mulai%20sewa%3A%20${inputs.start_date || ''}%0AJumlah%20hari%3A%20${inputs.total_days || ''}%20hari%0AUnit%20Pilihan%3A%20${inputs.car || ''}%0ALokasi%20Pengantaran%3A%20${inputs.location || 'Ambil di kantor'}%0A&apikey=3634409`
    const booking = () => {
        setShowForm(true)
        Axios.get(notif1)
        .then(response => {
            console.log('success')
        })

        Axios.get(notif2)
        .then(response => {
            console.log('success')
        })
    }

    return (
        <>
            <Card hidden={showForm} className="card-rent-car card-booking">
                <form onSubmit={handleSubmit} className="align-left">
                    <Row>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Atas Nama</Form.Label>
                                <Form.Control type="text" name="name" onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group>
                                <Form.Label>Nomor HP</Form.Label>
                                <Form.Control type="number" name="phone" onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>Tanggal mulai sewa</Form.Label>
                                <Form.Control type="date" name="start_date" onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Jumlah Hari</Form.Label>
                                <Form.Control type="number" name="total_days" onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="auto">
                            <Form.Group>
                                <Form.Label>Pilih Mobil</Form.Label>
                                <div>
                                    <select value={selectedValue} onChange={handleChange}>
                                        <option value="agya">Toyota Agya</option>
                                        <option value="calya">Toyota Calya</option>
                                        <option value="avanza">Toyota Avanza</option>
                                        <option value="veloz">Toyota Veloz</option>
                                        <option value="innova">Toyota Innova</option>
                                        <option value="innova reborn">Toyota Innova Reborn</option>
                                        <option value="hiace">Toyota Hiace</option>
                                        <option value="ayla">Daihatsu Ayla</option>
                                        <option value="sigra">Daihatsu Sigra</option>
                                        <option value="xenia">Daihatsu Xenia</option>
                                        <option value="brio">Honda Brio</option>
                                    </select>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>Lokasi Pengantaran</Form.Label>
                                <Form.Control type="text" name="location" placeholder="*kosongkan bila ingin mengambil di kantor" onChange={handleInputChange} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type="submit" onClick={booking}>Booking Sekarang</Button>
                </form>
            </Card>
            <div className="card card-booking animate__animated animate__fadeInDown" hidden={!showForm}>
                <img src={Success} width="50" height="50" className="align-self-center"/>
                <h3 className="title">Booking Berhasil</h3>
                <p className="subtitle">Selamat Bookinganmu telah berhasil, tim kami akan segera menghubungimu</p>
            </div>
        </>
    );
};

export default BookingForm;
