import React, {useEffect} from 'react';
import { fetchCategory } from '../../actions';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import image from '../../assets/image2.jpg'
import Header from '../header';
import './home.scss'

const Home = p => {

    useEffect(() => {
		p.fetchCategory('')
    }, []);

    const url = 'https://api.awakkerja.com/public'

    const featured_services = [
        {
            title: 'Jasa reparasi Laptop dan Komputer',
            slug: '/1/jasa-reparasi-laptop-dan-komputer',
            image: 'https://api.awakkerja.com/public/public/uploads/1642552433_cd27b23c40e880de008e.jpg',
            price: 'Rp 50.000',
            location: 'Banda Aceh'
        },
        {
            title: 'Guide Diving Wisata Sabang',
            slug: '/30/guide-diving-wisata-sabang',
            image: 'https://api.awakkerja.com/public/public/uploads/1665240397_ff0f9ec5628aa5d4494b.jpeg',
            price: 'Rp 200,000',
            location: 'Banda Aceh'
        },
        {
            title: 'Bongkar Pasang Gorden',
            slug: '/4/bongkar-pasang-gorden',
            image: 'https://api.awakkerja.com/public/public/uploads/1644422023_62d40566c93649d53ef8.jpeg',
            price: 'Rp 35.000',
            location: 'Kota Sabang'
        },
        {
            title: 'Terima jasa angkutan barang Banda Aceh - Aceh Besar',
            slug: '/13/terima-jasa-angkutan-barang-banda-aceh---aceh-besar',
            image: 'https://api.awakkerja.com/public/public/uploads/1645063255_a614865d03fd15f4044f.jpg',
            price: 'Rp 100.000',
            location: ' '
        }
    ]

	return (
		<div>
			<h1 hidden>layanan peyedia jasa terlengkap dan termurah di awakkerja.com</h1>
			<div className="row justify-content-center margin-0">
				<div className="col-md-8 home" >
					<Header />
					<div>
						<hr style={{ marginBottom: 50 }} className="d-md-block d-none" />
					</div>
					<h2 className='my-3' >Layanan Jasa Pilihan</h2>
					{featured_services.length > 0 &&
						<div className="row horizontal-scroll">
							{featured_services.map((item, key) => {
								return (
									<div key={key} className="col-md-3 col-6 fade-in">
										<a href={item.slug}>
											<div className="card category-image">
												<img loading={key > 3 ? "lazy" : ''} className='image-fit' title={item.title} alt={"kategory " + item.title} src={(item.image) ? item.image : image || <Skeleton />} />
												<span className='price-label'>{item.price}</span>
											</div>
										</a>
										<div className="mb-20">
											<b>{item.title || <Skeleton />}  {item.location || <Skeleton />}</b>
										</div>
									</div>
								)
							})}
						</div>
					}
					<div>
						<hr style={{ marginBottom: 25 }} className="d-md-block d-none" />
					</div>
					<h3 style={{ marginBottom: 25 }} >Kategori Jasa</h3>
					{p.dataCategory.length > 0 &&
						<div className="row">
							{p.dataCategory.map((item, key) => {
								return (
									<div key={key} className="col-md-4 col-6 fade-in">
										<a href={'/kategori/' + item.slug}>
											<div className="card category-image">
												<img className='w-100' loading={key > 1 ? "lazy" : ''} title={"kategory " + item.title} alt={item.title} src={(item.image) ? url + item.image.replace('.', '') : image || <Skeleton />} />
											</div>
										</a>
										<div className="text-align-center mb-20">
											<b>{item.title || <Skeleton />}</b>
										</div>
									</div>
								)
							})}
						</div>
					}
				</div>
			</div>
		</div>
	)
}

const mapStateTop = state => {
	return {
        dataCategory: state.jasa.dataCategory
	}
}

const mapDispatchTop = dispatch => {
	return {
		fetchCategory: (params) => {
			dispatch(fetchCategory(params))
        }
	}
}

export default connect(mapStateTop, mapDispatchTop)(Home);